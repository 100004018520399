import React from "react"
import Layout from "../../../templates/layout"
import SEO from "../../../components/seo/seo"
import Hero from "../../../components/hero/hero"
import HaveQuestions from "../../../components/haveQuestions/haveQuestions"
import ExpandingCard from "../../../components/ExpandingCard/ExpandingCard"
import IconCard from "../../../components/iconCard/iconCard"
import BrandLogo from "../../../images/logos/elitek-logo.svg"
import QuestionsIcon from "../../../images/icons/icon-questions-elitek.svg"
import HeroIcon from "../../../images/icons/elitek_support_icon.svg"

import EnrollmentForm from "../../../images/icons/elitek/enrollment-form.png";
import spanishEnrollmentForm from "../../../images/icons/elitek/spanish-enrollment-form.png";
import Officesupportbrochure from "../../../images/icons/hcp/office-support-brochure.png";
import PatientAssistanceProgramflashcard from "../../../images/icons/hcp/patient-assistance-program-flashcard.png";
import CopayProgramflashcard from "../../../images/icons/hcp/copay-program-flashcard.png";

import Alternatesourcesofcoverageflashcard from "../../../images/icons/hcp/alternate-sources-coverage-flashcard.png";
import Summarybenefitsflashcard from "../../../images/icons/hcp/summary-benefits-flashcard.png";
import CMSannotatedform from "../../../images/icons/hcp/cms-annotated-form.png";
import Priorauthorizationchecklist from "../../../images/icons/hcp/prior-authorization-checklist.png";
import Payerdenialflashcard from "../../../images/icons/hcp/payer-denial-flashcard.png";

import Letterofappealstemplate from "../../../images/icons/hcp/letter-appeals-template.png";
import Letterofmedicalnecessitytemplate from "../../../images/icons/hcp/letter-medical-necessity-template.png";
import Letterofmedicalexception from "../../../images/icons/hcp/letter-medical-exception-template.png";
import Billingandcodingguide from "../../../images/icons/hcp/billing-and-coding-guide.png";
import BackToTop from "../../../components/backToTop/BackToTop"
import PrescribingInfo from "../../../components/prescribingInfo/prescribingInfo"

const pageContent = () => (
  <Layout pageid="page-resources" pageClass="mobile-height-hero">
    <SEO
      title="Find CareASSIST resources and additional support options for eligible patients prescribed ELITEK® (rasburicase) | HCP Site"
      keywords="CareASSIST, Sanofi, ELITEK® (rasburicase), HCP Site, Resource Support"
      description="Download a brochure, a template, or explore online resources offering additional support for ELITEK from CareASSIST. See full Prescribing Info, including Boxed WARNING"
    />

    <Hero
      brandLogo={BrandLogo}
      headline="Resource support"
      copy="We help connect patients to independent support organizations and resources throughout their treatment journey"
      heroImgClass="hero-icon"
      hasButton={false}
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Resource Support Icon"
      brandAlt="Elitek logo"
    />



    <section className="content-section mobile-p-t-20">
      <blockquote className="elitek mb-2 sm-max-width p-t-45">
        <ExpandingCard
          cardClass="full elitek"
          mainContent={() => (
            <div className="expanding-card-content header">
              <h2>CareASSIST enrollment and program overview </h2>
            </div>
          )}
          hiddenContent={() => (
            <div className="expanding-card-content hidden">
              <div className="grid-cols-sm-1 grid-cols-2 gap-30">
                <IconCard
                  icon={EnrollmentForm}
                  alt="Enrollment form"
                  cardClass="download-card elitek"
                  cardTitle="Enrollment form"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-program-enrollment-form.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the enrollment form"
                  linkId="hcp-elitek-resource-enroll-form"
                  isBlur={true}
                />
                <IconCard
                  icon={spanishEnrollmentForm}
                  alt="Spanish Enrollment Form Icon"
                  cardClass="download-card elitek"
                  cardTitle="Spanish enrollment form"
                  cardText=""
                  isExternalLink={true}
                  link="../../../pdfs/careassist-spanish-enrollment-form.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the Copay proof of expense form"
                  linkId="hcp-elitek-resource-spanish-enroll-form"
                  isBlur={true}
                />
                <IconCard
                icon={Officesupportbrochure}
                alt="CareASSIST HCP Overview Brochure"
                cardClass="download-card elitek"
                cardTitle="CareASSIST HCP overview brochure"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-patient-support-program-overview-resource-hcp.pdf"
                linkClass="download-icon"
                linkTitle="Download the enrollment form"
                isBlur={true}
                linkId="hcp-elitek-resource-overview-brochure"
              />
              </div>
              
            </div>
          )}
        />
        <ExpandingCard
          cardClass="full elitek"
          mainContent={() => (
            <div className="expanding-card-content">
              <h2>Financial and reimbursement support</h2>
            </div>
          )}
          hiddenContent={() => (
            <div className="expanding-card-content hidden">
              <h3>Financial tools </h3>
              <div className="grid-cols-sm-1 grid-cols-2 gap-30">
                <IconCard
                icon={CopayProgramflashcard}
                alt="Copay Program flashcard"
                cardClass="download-card elitek"
                cardTitle="Copay Program flashcard"
                cardText=""
                hasButton={false}
                isExternalLink={true}
                link="../../../pdfs/careassist-copay-program-hcp-flashcard.pdf"
                linkClass="download-icon"
                linkTitle="Download the enrollment form"
                isBlur={true}
                linkId="hcp-elitek-resource-copay-program-flashcard"
                />
                <IconCard
                  icon={PatientAssistanceProgramflashcard}
                  alt="Patient Assistance Program flashcard"
                  cardClass="download-card elitek"
                  cardTitle="Patient Assistance Program flashcard"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-pap-flashcard.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the enrollment form"
                  isBlur={true}
                  linkId="hcp-elitek-resource-pap-flashcard"
                />
                
              </div>
              <hr className="elitek only-mbl" />
              <h3>Reimbursement tools</h3>
              <div className="grid-cols-sm-1 grid-cols-2 gap-30">
                <IconCard
                  icon={Summarybenefitsflashcard}
                  alt="Summary of benefits flashcard"
                  cardClass="download-card elitek"
                  cardTitle="Summary of benefits flashcard"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-summary-of-benefits-flashcard.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the Summary of benefits flashcard"
                  isBlur={true}
                  linkId="hcp-elitek-resource-benefits-flashcard"
                />
                <IconCard
                  icon={CMSannotatedform}
                  alt="Sample CMS annotated form flashcard"
                  cardClass="download-card elitek"
                  cardTitle="Sample CMS annotated form flashcard"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-sample-annotated-cms-form-flashcard.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the Sample CMS annotated form flashcard"
                  isBlur={true}
                  linkId="hcp-elitek-resource-annotated-form-flashcard"
                />
                <IconCard
                  icon={Priorauthorizationchecklist}
                  alt="Prior authorization checklist"
                  cardClass="download-card elitek"
                  cardTitle="Prior authorization checklist"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-prior-authorization-checklist.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the Prior authorization checklist"
                  isBlur={true}
                  linkId="hcp-elitek-resource-prior-authorization-checklist"
                />
                <IconCard
                  icon={Payerdenialflashcard}
                  alt="Payer denial flashcard"
                  cardClass="download-card elitek"
                  cardTitle="Payer denial flashcard"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-payer-denial-flashcard.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the Payer denial flashcard"
                  isBlur={true}
                  linkId="hcp-elitek-resource-payer-denial-flashcard"
                />
                <IconCard
                  icon={Billingandcodingguide}
                  alt="ELITEK billing and coding guide"
                  cardClass="download-card elitek"
                  cardTitle="ELITEK billing and coding guide"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/elitek-billing-coding-guide.pdf"
                  linkClass="download-icon"
                  linkTitle="Download the billing and coding guide"
                  linkId="hcp-elitek-resource-billing-coding-guide"
                  isBlur={true}
              />
              </div>
              <hr className="elitek only-mbl" />
              <h3>Sample letters </h3>
              <div className="grid-cols-sm-1 grid-cols-2 gap-30">

                <IconCard
                  icon={Letterofappealstemplate}
                  alt="Letter of appeals template"
                  cardClass="download-card elitek"
                  cardTitle="Letter of appeals template"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-sample-letter-of-appeals.docx"
                  linkClass="download-icon"
                  linkTitle="Download the Letter of appeals template"
                  isBlur={true}
                  linkId="hcp-elitek-resource-letter-of-appeal-template"
                />
                <IconCard
                  icon={Letterofmedicalnecessitytemplate}
                  alt="Letter of medical necessity template"
                  cardClass="download-card elitek"
                  cardTitle="Letter of medical necessity template"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-sample-letter-of-medical-necessity.docx"
                  linkClass="download-icon"
                  linkTitle="Download the Letter of medical necessity template"
                  isBlur={true}
                  linkId="hcp-elitek-resource-medical-necessity-template"
                />
                <IconCard
                  icon={Letterofmedicalexception}
                  alt="Letter of medical exception"
                  cardClass="download-card elitek"
                  cardTitle="Letter of medical exception template"
                  cardText=""
                  hasButton={false}
                  isExternalLink={true}
                  link="../../../pdfs/careassist-sample-letter-of-medical-exception.docx"
                  linkClass="download-icon"
                  linkTitle="Download the Template of medical exception template"
                  isBlur={true}
                  linkId="hcp-elitek-resource-medical-exception-template"
                  />
              </div>
            </div>
          )}
        />

        <ExpandingCard
          cardClass="full elitek"
          mainContent={() => (
            <div className="expanding-card-content header">
              <h2>Online resources*</h2>
            </div>
          )}
          hiddenContent={() => (
            <div className="expanding-card-content hidden">
              <p className="mb-2">
                Certain patients may require information or assistance beyond what
                CareASSIST can offer. Below are links to external organizations
                that may be able to help. You or your patients may also call &nbsp;
                  <a className="phone-number" href="tel:1-833-930-2273">1-833-WE+CARE</a> (<a className="phone-number" href="tel:1-833-930-2273">1-833-930-2273</a>) to learn more.
              </p>

              <div className="grid-cols-sm-1 grid-cols-2 gap-20">
              <IconCard
                alt="HealthCare"
                cardClass="external-link-card clickable-title"
                cardTitle="HealthCare.gov"
                cardText=""
                hasButton={false}
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://healthcare.gov"
                modalLinkId="hcp-elitek-resource-healthcare-gov"
              />
              <IconCard
                alt="Medicaid.gov"
                cardClass="external-link-card clickable-title"
                cardTitle="Medicaid.gov"
                cardText=""
                hasButton={false}
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://medicaid.gov"
                modalLinkId="hcp-elitek-resource-medicaid-gov"
              />
              <IconCard
                alt="Medicare.gov"
                cardClass="external-link-card clickable-title"
                cardTitle="Medicare.gov"
                cardText=""
                hasButton={false}
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://medicare.gov"
                modalLinkId="hcp-elitek-resource-medicare-gov"
              />
              <IconCard
                alt="Patient Advocate Foundation"
                cardClass="external-link-card clickable-title"
                cardTitle="Patient Advocate Foundation"
                cardText=""
                hasButton={false}
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://www.patientadvocate.org/"
                modalLinkId="hcp-elitek-resource-patientadvocate-org"
              />
              <IconCard
                alt="Patient Advocate Network Foundation"
                cardClass="external-link-card clickable-title"
                cardTitle="Patient Advocate Network Foundation"
                cardText=""
                hasButton={false}
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://www.panfoundation.org/"
                modalLinkId="hcp-elitek-resource-panfoundation-org"
              />
              <IconCard
                alt="American Cancer Society"
                cardClass="external-link-card clickable-title"
                cardTitle="American Cancer Society"
                cardText=""
                hasButton={false}
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://cancer.org"
                modalLinkId="hcp-elitek-resource-cancer-org"
              />
              <IconCard
                alt="Cancer Support Community"
                cardClass="external-link-card clickable-title"
                cardTitle="Cancer Support Community"
                cardText=""
                hasButton={false}
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://www.cancersupportcommunity.org/"
                modalLinkId="hcp-elitek-resource-cancersupportcommunity-org"
              />
              <IconCard
                alt="National Comprehensive Cancer Network<sup>®</sup>"
                cardClass="external-link-card clickable-title"
                cardTitle="National Comprehensive Cancer Network<sup>®</sup>"
                cardText=""
                hasButton={false}
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://nccn.org"
                modalLinkId="hcp-elitek-resource-nccn-org"
              />
              <IconCard
                alt="CancerCare"
                cardClass="external-link-card clickable-title"
                cardTitle="CancerCare"
                cardText=""
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://www.cancercare.org/"
                modalLinkId="hcp-elitek-resource-cancercare-org"
              />
              <IconCard
                alt="FindHelp"
                cardClass="external-link-card clickable-title"
                cardTitle="FindHelp.org"
                cardText=""
                isModalLink={true}
                isClickableTitle={true}
                modalLink="https://www.FindHelp.org/"
                modalLinkId="hcp-elitek-resource-findhelp-org"
              />
              </div>



              <div className="important-info elitek pt-1">
                <p className="hang">*This listing is provided as a resource only and does not constitute an endorsement by Sanofi of any particular organization or its programming. Additional resources on this topic may be available and should be investigated. Sanofi does not review or control the content of non-Sanofi websites. </p>
                <h4 className="mt-0">INDICATION</h4>
                <p>
                  ELITEK is indicated for the initial management of plasma uric
                  acid levels in patients with leukemia, lymphoma, and solid tumor
                  malignancies who are receiving anticancer therapy expected to
                  result in tumor lysis and subsequent elevation of plasma uric
                  acid. ELITEK is indicated only for a single course of treatment.
                </p>
                <h4>IMPORTANT SAFETY INFORMATION</h4>
                <div className="warning">
                  <h4 className="color-black">
                    WARNING: HYPERSENSITIVITY REACTIONS, HEMOLYSIS,
                    METHEMOGLOBINEMIA, AND INTERFERENCE WITH URIC ACID
                    MEASUREMENTS
                  </h4>
                  <ul>
                    <li>
                      Hypersensitivity Reactions: ELITEK can cause serious and
                      fatal hypersensitivity reactions including anaphylaxis.
                      Immediately and permanently discontinue ELITEK in patients
                      who experience a serious hypersensitivity reaction.
                    </li>
                    <li>
                      Hemolysis: Do not administer ELITEK to patients with
                      glucose-6-phosphate dehydrogenase (G6PD) deficiency.
                      Immediately and permanently discontinue ELITEK in patients
                      developing hemolysis. Screen patients at higher risk for
                      G6PD deficiency (e.g., patients of African or Mediterranean
                      ancestry) prior to starting ELITEK.{" "}
                    </li>
                    <li>
                      Methemoglobinemia: ELITEK can result in methemoglobinemia in
                      some patients. Immediately and permanently discontinue
                      ELITEK in patients developing methemoglobinemia.{" "}
                    </li>
                    <li>
                      Interference with Uric Acid Measurements: ELITEK
                      enzymatically degrades uric acid in blood samples left at
                      room temperature. Collect blood samples in prechilled tubes
                      containing heparin and immediately immerse and maintain
                      sample in an ice water bath. Assay plasma samples within 4
                      hours of collection.{" "}
                    </li>
                  </ul>
                </div>
                <h4>CONTRAINDICATIONS</h4>
                <p>
                  ELITEK is contraindicated in patients with a history of
                  anaphylaxis or severe hypersensitivity to rasburicase or in
                  patients with development of hemolytic reactions or
                  methemoglobinemia with rasburicase. ELITEK is contraindicated in
                  individuals deficient in glucose-6-phosphate dehydrogenase
                  (G6PD).
                </p>
                <h4>ADVERSE REACTIONS</h4>
                <p>
                  Most common adverse reactions (incidence ≥20%), when used
                  concomitantly with anticancer therapy, are vomiting, nausea,
                  fever, peripheral edema, anxiety, headache, abdominal pain,
                  constipation, diarrhea, hypophosphatemia, pharyngolaryngeal
                  pain, and increased alanine aminotransferase.
                </p>
                <h4>USE IN SPECIFIC POPULATIONS</h4>
                <ul>
                  <li>
                    <b>Pregnancy:</b> Consider the benefits and risks of ELITEK
                    and possible risks to the fetus when prescribing ELITEK to a
                    pregnant woman
                  </li>
                  <li>
                    <b>Lactation:</b> Because of the potential for serious adverse
                    reactions in the breastfed child, advise patients that
                    breastfeeding is not recommended during treatment with ELITEK
                    and for 2 weeks after the last dose
                  </li>
                </ul>
              </div>
            </div>
          )}
        />
      </blockquote>
      <div className="mobile-m-t-40">
      <PrescribingInfo linkId="hcp-elitek-resource-support-prescribing-information" link="https://products.sanofi.us/elitek/elitek.html" />
        <HaveQuestions icon={QuestionsIcon} />
      </div>
    </section>
    <div className="back-to-top-container"> 
      <BackToTop />
    </div>
  </Layout>
);

export default pageContent